<template>
  <div class="cooperation">
    <div class="sec1 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-1 order-lg-0 px-md-0 px-4">
            <h2 class="black pb-4">
              Оказывайте услуги
              и
              <span class="red">зарабатывайте</span>
            </h2>
            <h4 class="black pb-4">Приглашаем специалистов, <br> предоставляющих следующие услуги:</h4>
            <ul class="list">
              <li>Помощь в оформлении банковских карт и документов</li>
              <li>Организация экскурсий</li>
              <li>Трансфер</li>
              <li>Услуги переводчика</li>
              <li> Шопинг-консультации</li>
            </ul>
          </div>
          <div class="col-lg-6 order-lg-1 order-0"><img src="img/1-bg-cooperation.png"></div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <router-link :to="{name: 'FormPage', params:{formType: 'partner'}}" class="btn">Стать партнером <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>

        </div>
      </div>
    </div>
    <div class="sec2 py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-5 px-5"><img class="coop_bg" src="img/2-bg.png" alt=""></div>
          <div class="col-md-7 ">
            <div class="h-100 d-flex flex-column justify-content-center px-md-0 px-4">
              <h3 class="black">Что для этого нужно</h3>
              <ul class="list">
                <li>Вы проживаете в Турции</li>
                <li>Знаете русский и турецкий языки</li>
                <li> Имеете опыт оказания услуг российским путешественникам</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec3">
      <div class="container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0 px-4">
            <div class="h-100 d-flex flex-column justify-content-center">
              <h4 class="black">
                Оформление заказов
                в интернет-магазинах
              </h4>
              <ul class="list">
                <li>Клиент оформляет заявку на сайте</li>
                <li> Мы присылаем вам ссылку на товар в интернет-магазине</li>
                <li>Вы оформляете заказ и отправляете клиенту в Россию</li>
                <li>Мы переводим сумму покупки и вознаграждение на ваш счет или банковскую карту</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 order-md-1 order-0 p-5"><img class="coop_bg" src="img/3-bg.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="sec4">
      <div class="container">
        <div class="row">
          <div class="col-md-5 p-5"><img src="img/4-bg-coop.png" alt="" class="coop_bg"></div>
          <div class="col-md-7 px-4">
            <div class="h-100 d-flex flex-column justify-content-center">
            <h4 class="black">Оказание услуг</h4>
            <ul class="list">
              <li>Клиент оформляет заявку на сайте
              </li>
              <li> Мы отправляем заявку вам
              </li>
              <li> Вы рассчитываете стоимость
              </li>
              <li> Клиент оплачивает услугу на сайте или при встрече через выданный вам мобильный терминал
              </li>
              <li>После оказания услуги средства поступают на ваш счет или банковскую карту</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec6 py-5">
<div class="container">
  <h3 class="black text-center">Готовы начать?</h3>
  <div class="row d-none d-lg-flex">
    <div class="col-6">
      <div class="s3_services_el s3_services_el1 my-3">
        <div class="icon"><img src="img/6icon1.png" alt=""></div>
        <h4 class="black pt-5">Зарегестрируйтесь в Личном кабинете <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>


      </div>
    </div>
    <div class="col-6">
      <div class="s3_services_el s3_services_el2 my-3">
        <div class="icon"><img src="img/6icon2.png" alt=""></div>
        <h4 class="black pt-5">Получите мобильный терминал для приема оплаты на месте  <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>


      </div>
    </div>
    <div class="col-6">
      <div class="s3_services_el s3_services_el3 my-3">
        <div class="icon"><img src="img/6icon3.png" alt=""></div>
        <h4 class="black pt-5">Исполняйте заказы
          на покупку товаров
          и оказание услуг <i><img src="img/arrow-red-24-px.svg" alt=""></i>
        </h4>


      </div>
    </div>
    <div class="col-6">
      <div class="s3_services_el s3_services_el4 my-3">
        <div class="icon"><img src="img/6icon4.png" alt=""></div>
        <h4 class="black pt-5">Принимайте оплату
          на свой счет или банковскую карту <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
      </div>
    </div>
  </div>
  <div class="d-block d-lg-none">
    <div class="sliderCooperations" >
      <div>
        <div class="s3_services_el s3_services_el1 my-3">
        <div class="icon"><img class="d-inline-block" src="img/6icon1.png" alt=""></div>
        <h4 class="black pt-5">Зарегестрируйтесь в Личном кабинете <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>


  </div>
      </div>
      <div>
        <div class="s3_services_el s3_services_el2 my-3">
          <div class="icon"><img class="d-inline-block" src="img/6icon2.png" alt=""></div>
          <h4 class="black pt-5">Получите мобильный терминал для приема оплаты на месте  <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>


        </div>
      </div>
      <div>
        <div class="s3_services_el s3_services_el3 my-3">
          <div class="icon"><img class="d-inline-block" src="img/6icon3.png" alt=""></div>
          <h4 class="black pt-5">Исполняйте заказы
            на покупку товаров
            и оказание услуг <i><img class="d-inline-block" src="img/arrow-red-24-px.svg" alt=""></i>
          </h4>


        </div>
      </div>
      <div>
        <div class="s3_services_el s3_services_el4 my-3">
          <div class="icon"><img src="img/6icon4.png" alt=""></div>
          <h4 class="black pt-5">Принимайте оплату
            на свой счет или банковскую карту <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center mt-5">
    <router-link :to="{name: 'FormPage', params:{formType: 'partner'}}" class="btn">Стать партнером <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>

  </div>
</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Cooperation",
  mounted(){
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth'
    });
    // eslint-disable-next-line no-undef
    $('.sliderCooperations').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      infinite: true,
    });
  },
  beforeUnmount() {
    //eslint-disable-next-line no-undef
    $('.sliderCooperations').slick('unslick');

  }
}
</script>

<style>
</style>
